<!-- ########################## <Template> ########################## -->
<template lang="">
    <div class="choixHeader ">
         <!--  -->
                <span class="k-icon k-i-make-horizontal-spacing-equal"></span>
                Min:&nbsp;<span>>=</span>  <InputText     type="number" 
                                    :min="-9999999999999" :max="9999999999999" 
                                    
                                    v-model="minValue" 
                                    @change="fnSaveIndicateurChoix" 
                /> 
                Max: <span v-if="lastOne">&lt;=</span><span v-else>&lt;</span> <InputText     type="number" 
                                    :min="-9999999999999" :max="9999999999999" 
                                    maxlength="15"
                                
                                    v-model="maxValue" 
                                    @change="fnSaveIndicateurChoix" 
                                    onKeyDown="if(this.value.length==2) return false;" 
                />
                Note: <InputText    type="number"
                                    maxlength="3"
                                    @change="fnSaveIndicateurChoix" 
                                    v-model.number="noteLocal"	 
                                    :min="-99" :max="echelleIndicateur" 
                                    class="inputNote"
                                    :class="validNoteClass"
                /> 
                / {{echelleIndicateur}}
                &nbsp;
                
                <kendo-button icon="delete" @kendoclick="fnDeleteIndicateurChoix"></kendo-button>
        <!--  -->
    </div>
    <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import {_} from 'vue-underscore';
import { mapGetters } from 'vuex'

export default {
    name: "ClientSettingsIFChoix",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button
    },
    //---------------------------- emits ------------------------------
    emits: ["choixDeleted","choixChanged"],
    //---------------------------- props ------------------------------
    props: {
        choixId: Number,
        min: Number,
        max: Number,
        note: Number,
        ordre: Number,
        indicateurId: Number,
        lastOne: Boolean
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            errorMessage : "",
            libelleChoixInitial:this.libelleChoix,
            minValue:this.min,
            maxValue:this.max,
            noteLocal:""
        }

    }, //end data()
    computed: {
        
        validNoteClass()
        {
            var isValid = this.noteLocal <= this.echelleIndicateur;
            return isValid?"formuleValide" :"formuleNonValide";
        },
        ...mapGetters("clientSettingsStoreModule",[
            'listeIndicateursIF',
            'totalEchellesIFs',
            'getEchelleIF',
        ]),
        echelleIndicateur()
        {
            return this.getEchelleIF(this.indicateurId);
        }
    },
    //------------------------------- Created --------------------------
    created() {
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("==================================== mounted() => ClientSettingsIF3Choix");
        this.noteLocal = this.note
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {

    },

    //-------------------------------- Methods --------------------------
    methods: {
        
        fnDeleteIndicateurChoix()
        {
            this.log("----------------- fnDeleteIndicateurChoix");
            let confirmAction = confirm("êtes-vous sûr de vouloir continuer la suppression?");
            if (confirmAction) {
                CRUDService.delete(this.choixId,'indicateurfinancierlistechoix').then(
                    (response) => {
                        this.log(response.data);
                        this.$emit("choixDeleted",this.choixId);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 
            } else {
                this.log("Suppression annulée");
            }
            

        },
        fnSaveIndicateurChoix()
        {
            this.log("----------------- fnSaveIndicateurChoix");

            var choixToUpdate = { id: this.choixId, 
                                indicateurId: this.indicateurId, 
                                min: this.isNumeric(this.minValue) ?this.minValue:-9999999999999, 
                                max: this.isNumeric(this.maxValue) ?this.maxValue:9999999999999, 
                                note: this.noteLocal?this.noteLocal:0};
            
            this.minValue = choixToUpdate.min;
            this.maxValue = choixToUpdate.max;

            if(this.noteLocal > this.echelleIndicateur){
                this.toastWarn("Note est supérieur à l'echelle, sauvegarde interrompue !");
            }else {
          
                this.log(choixToUpdate);
                CRUDService.update(choixToUpdate,'indicateurfinancierlistechoix').then(
                    (response) => {
                        this.log(response.data);
                        this.$emit("choixChanged",this.choixId,this.minValue);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 

            }
        }

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
  .choixHeader
  {
      padding-left:30px;
  }

    
</style>