<!-- ########################## <Template> ########################## -->
<template>
    <div>
        <div style="display:block;align-items:center">
            
                <div style="font-size:1.1rem;font-weight:bold">Limite de crédit minimum</div>
               
                <div style="display:flex;align-items:center; min-height:45px">
                    <div style="display: flex; align-items: center; ">
                        &nbsp;&nbsp;Activation&nbsp;&nbsp;<InputSwitch v-model="limiteCreditMinimumActivationBoolean" @change="fnUpdateClientLimiteCreditActivation"/>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="p-field" v-show="limiteCreditMinimumActivationBoolean">
                        <label for="LimiteCreditMinimum" class="p-mb-3">Limite de crédit minimum</label>
                        &nbsp;
                        <InputText type="number" id="locale-us" v-model="selectedClient.limiteCreditMinimum" mode="decimal" locale="ar-MA" :minFractionDigits="0" @change="fnUpdateClientLimiteCreditActivation" />
                        <!-- <ErrorMessage name="LimiteCreditMinimum" class="error-feedback" /> -->
                    </div>
                </div>
        </div>
    </div>
    <div  style="position: relative; min-height:30px; padding-top:10px">
        
         <!--  -->
         <div style="font-size:1.1rem;font-weight:bold">Paliers limite de crédit</div>
            <div :class="'topRightCorner'">
             
                <kendo-button icon="add" @kendoclick="fnAddlimitecredit">ajouter paramétrage limite credit</kendo-button>
            </div>
    </div>
            <div v-for="limitecredit in listeChoix" :key="limitecredit.id">
                <ClientSettingsLimiteCredit2Choix 
                                                :key="limitecredit.id"
                                                :limitecreditId="limitecredit.id"
                                                :clientId="limitecredit.clientId"
                                                :scoreMin="limitecredit.scoreMin"
                                                :scoreMax="limitecredit.scoreMax"
                                                :montantLimiteQualitatif="limitecredit.montantLimiteQualitatif"
                                                :pourcentLimiteCredit="limitecredit.pourcentLimiteCredit"
                                                :lastOne = limitecredit.lastOne
                                                @limitecreditDeleted="onlimitecreditDeleted"
                                                @limitecreditChanged="onlimitecreditChanged"
                 >
                 </ClientSettingsLimiteCredit2Choix>        
            </div>
        <!--  -->
    
    
    <div  class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>

<!-- ########################## <Script> ########################## -->
<script>
import kendo from '@progress/kendo-ui'
import CRUDService from "../../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import ClientSettingsLimiteCredit2Choix from "./ClientSettingsLimiteCredit2Choix.vue";

import {_} from 'vue-underscore';

export default {
    name: "ClientSettingsRec1Component",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button,
       ClientSettingsLimiteCredit2Choix
    },
    //---------------------------- props ------------------------------
    props: {
        clientId: Number,
        selectedClient: Object
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            listeChoix: [],
            errorMessage : "",
            limiteCreditMinimumActivationBoolean:false
        }

    }, //end data()
    computed: {
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("======================================================= mounted() => ClientSettingsRec1Component");
        this.fnLoadlimitecredits();
        this.limiteCreditMinimumActivationBoolean = this.selectedClient.activerChoixLimiteCreditMinimum?true:false;
    }, //end mounted 

    //-------------------------------- Methods --------------------------
    methods: {
        fnUpdateClientLimiteCreditActivation(event)
        {

            this.log("fnUpdateClientWorkflowValidation");

            this.selectedClient.activerChoixLimiteCreditMinimum = this.limiteCreditMinimumActivationBoolean?1:0;
    
            var endpoint = 'client/updatelimitecreditminimum';
            CRUDService.update(this.selectedClient,endpoint).then(
                (response) => {
                    this.log("--------------- returned fnSavePoidsScores response.data");
                    this.log(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
           // console.log(this.selectedClient.)
        },
        fnLoadlimitecredits()
        {
            var endpoint = this.isAdminApplication?'limitecreditadmincustom':'limitecreditclientcustom';
            
            CRUDService.get(this.clientId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadlimitecredits reponse");
                this.log(response.data);
                this.listeChoix = response.data;

                if(this.listeChoix[0])
                {
                    this.listeChoix[0]['lastOne'] = true
                }
               
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        },
        
        fnAddlimitecredit()
        {
            this.log("fnAddlimitecredit");
            var nouveaulimitecredit = { clientId: this.clientId, scoreMin:0, scoreMax:10, pourcentLimiteCredit:0 , montantLimiteQualitatif:0};
            CRUDService.create(nouveaulimitecredit,'limitecredit').then(
                (response) => {
                    this.log(response.data);
                    this.listeChoix.push(response.data);

                     setTimeout(() => {
                        window.scrollTo(0, document.body.scrollHeight? document.body.scrollHeight : document.documentElement.scrollHeight);
                    }, 200); 
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },

        onlimitecreditDeleted(deletedId)
        {
            this.log("onlimitecreditDeleted: "+deletedId);
            for( var i = 0; i < this.listeChoix.length; i++){ 
                if ( this.listeChoix[i].id === deletedId) { 
                    this.listeChoix.splice(i, 1); 
                    //the if is a must because it is used to only change the lastOne flag if the deleted one is the first
                    if(i==0)
                    {
                        this.listeChoix[0]['lastOne'] = true
                    }
                }
            }
        },

        onlimitecreditChanged(changedId,minValue)
        {
            debugger;
            this.log("onChoixChanged: "+changedId);
            for( var i = 0; i < this.listeChoix.length; i++){ 
                if ( this.listeChoix[i].id === changedId) { 
                    this.listeChoix[i].scoreMin = minValue
                    

                    var maxIndex = 0
                    this.listeChoix[maxIndex]['lastOne'] = false
                    for(var j=1;j<this.listeChoix.length;j++)
                    {
                        this.listeChoix[j]['lastOne'] = false
                        if(parseInt(this.listeChoix[j].scoreMin) > parseInt(this.listeChoix[maxIndex].scoreMin))
                        {
                            debugger;
                            maxIndex = j
                        }
                    }

                    this.listeChoix[maxIndex]['lastOne'] = true
                }
                
            }
        },  



    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  

    
</style>