<!-- ########################## <Template> ########################## -->
<template lang="">
    <div class="divPanel limitecreditHeader " style="position: relative;">
                <div :class="'topRightCorner'">
                    <kendo-button icon="delete" @kendoclick="fnDeletelimitecredit"></kendo-button>
                </div>
        <!--  -->
                
                <!-- <table style="width:95%"> -->
                    <div style="display:flex">
                        <div>
                            <div style="display:flex;">
                                <div style="padding-right:20px">
                                    Score min &nbsp;<span>>=</span>&nbsp;
                                    &nbsp;<InputText type="number" @change="fnSaveLimiteCredit" v-model="scoreMinLocal" :number="scoreMinLocal" :class="'inputNote'" max="10" min="0"/>
                                </div>
                                <div style="padding-right:60px">
                                    Score max <span v-if="lastOne">&lt;=</span><span v-else>&lt;</span>
                                    &nbsp;<InputText type="number" @change="fnSaveLimiteCredit" v-model="scoreMaxLocal" :number="scoreMaxLocal" :class="'inputNote'" max="10" min="0"/>
                                </div>
                            </div>
                        </div>
                        <div ></div>
                        <div style="padding-right:30px" tooltip="Utilisé quand il y'a un score financier" >Pourcentage de la VNC &nbsp;
                            <InputText 
                                type="number" 
                                @change="fnSaveLimiteCredit" 
                                 
                                v-model="pourcentLimiteCreditLocal" 
                                max="100" min="0"/>% 
                        </div>
                        <div  tooltip="Utilisé quand il y'a le score qualitatif seulement">Limite montant qualitatif&nbsp;
                            <InputText 
                                type="number" 
                                @change="fnSaveLimiteCredit" 
                                 
                                v-model="montantLimiteQualitatifLocal" 
                                />
                           
                        </div>
                        
                    </div>
                   
                <!-- </table> -->
                  
        
        <!--  -->
    </div>
    <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import {_} from 'vue-underscore';

export default {
    name: "ClientSettingsLimiteCredit2Choix",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button
    },
    //---------------------------- emits ------------------------------
    emits: ["limitecreditDeleted","limitecreditChanged"],
    //---------------------------- props ------------------------------
    props: {
        limitecreditId: Number,
        clientId: Number,
        scoreMin: Number,
        scoreMax: Number,
        pourcentLimiteCredit: Number,
        montantLimiteQualitatif:Number,
        lastOne:Boolean
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            limitecreditIdLocal : this.limitecreditId,
            clientIdLocal : this.clientId,
            scoreMinLocal : this.scoreMin,
            scoreMaxLocal : this.scoreMax,
            pourcentLimiteCreditLocal : this.pourcent,
            montantLimiteQualitatifLocal : this.montantLimiteQualitatif,
            errorMessage : "",
            flagToastDebounce:false,
        }

    }, //end data()
    mounted()
    {
        this.limitecreditIdLocal = this.limitecreditId
        this.clientIdLocal = this.clientId
        this.scoreMinLocal = this.scoreMin
        this.scoreMaxLocal = this.scoreMax
        this.pourcentLimiteCreditLocal = this.pourcentLimiteCredit
        this.montantLimiteQualitatifLocal = this.montantLimiteQualitatif

    },
     //-------------------------------- computed --------------------------
    computed: {
    
    },
    //-------------------------------- Methods --------------------------
    methods: {
        
        fnDeletelimitecredit()
        {
            this.log("----------------- fnDeletelimitecredit");
            let confirmAction = confirm("êtes-vous sûr de vouloir continuer la suppression?");
            if (confirmAction) {
                CRUDService.delete(this.limitecreditIdLocal,'limitecredit').then(
                    (response) => {
                        this.log(response.data);
                        this.$emit("limitecreditDeleted",this.limitecreditIdLocal);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 
            } else {
                this.log("Suppression annulée");
            }
            
        },
        fnSaveLimiteCredit()
        {
            
            this.log("----------------- fnSaveLimiteCredit");
            var limitecreditToUpdate = {  id: this.limitecreditIdLocal, 
                                            clientId: this.clientIdLocal, 
                                            scoreMin: this.isNumeric(this.scoreMinLocal) ?this.scoreMinLocal:-1, 
                                            scoreMax: this.isNumeric(this.scoreMaxLocal) ?this.scoreMaxLocal:101, 
                                            pourcentLimiteCredit: this.pourcentLimiteCreditLocal?this.pourcentLimiteCreditLocal:0,
                                            montantLimiteQualitatif:this.montantLimiteQualitatifLocal?this.montantLimiteQualitatifLocal:0
                                        };

            this.log(limitecreditToUpdate);
            var ref = this;
            CRUDService.update(limitecreditToUpdate,'limitecredit').then(
                (response) => {
                    
                    if(!ref.flagToastDebounce)
                    {
                        ref.flagToastDebounce = true
                        setTimeout(function(){
                            ref.flagToastDebounce = false
                        },1000)
                        console.log("-------Saved in debounce-------")
                        ref.toastSuccess("Valeur sauvegardée")
                    }
                    this.$emit("limitecreditChanged",this.limitecreditId,this.scoreMinLocal);
                    
                    
                    this.log(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            ); 
        }

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
  .limitecreditHeader
  {
      padding-left:30px;
  }

  .responsiveFirstDiv
    {
        width:330px
    }
  
  /* @media screen and (max-width: 1525px) {
        .responsiveFirstDiv
        {
            width: 50vw !important;
        }

    } */
    
</style>