<!-- ########################## <Template> ########################## -->
<template lang="">

    <Header></Header>
    <div class="pageTitle" > 
        <font-awesome-icon icon="caret-right" /> 
        Paramétrage des indicateurs clients
    </div>
    
    <div class="pageBodyContainer">        
        <div class="card" style="min-height:80vh">

            <!-- Informations client -->
            <div class="panel panel-default">
                <div class="panel-heading" style="">
                    <div style="display: inline-block;">
                    Choix du client
                    &nbsp;
                    </div>
                    <!--  -->
                    <div style="display: inline-block; width:500px">
                            <Dropdown   v-model="selectedDdlId" 
                                        :options="listeDesClients" 
                                        optionLabel="raisonSociale" 
                                        optionValue="id" 
                                        placeholder="" 
                                        @change="handleClientDdlValueChange"/>
                    </div>
                    <!--  -->
                </div>
                <!-- <div class="divPanel" >
                    
                    <span style="padding-left:30px"></span>
                </div> -->
            </div>

            <div v-if="selectedClient.id <= 0"  class="text-center">
                <img  src="../assets/settings.png"  style="width:300px; padding-top: 40px; padding-bottom:40px" />
            </div>

            <kendo-tabstrip ref="refKendoTabStrip" v-if="selectedClient.id > 0" >
                <ul>
                    <li class="k-state-active tabStribHeader" style="vertical-align:middle;border-bottom-color: #0d6efd;border-width: 2px;">
                        <span class="k-icon k-i-greyscale"></span>
                            Géneral
                    </li>
                    <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #f31700;border-width: 2px;">
                            <span class="k-icon k-i-dollar"></span>
                            Indicateurs financiers
                    </li>
                    <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #37b400;border-width: 2px;">
                            <span class="k-icon k-i-saturation"></span>
                            Indicateurs qualitatifs
                    </li>
                    <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: orange;border-width: 2px;">
                            <span class="k-icon k-i-graph"></span>
                            Recommandations
                    </li>
                    <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: purple;border-width: 2px;">
                            <span class="k-icon k-i-chart-ohlc"></span>
                            Limite de crédit
                            
                    </li>
                </ul>
                <div>
                    <!-- Poids indicateurs financiers vs. indicateurs qualitatifs -->
                    <ClientSettingsPoidsScoresComponent 
                                            v-if="selectedClient.id > 0" 
                                            ref="ClientSettingsPoidsScoresComponent" 
                                            :key="selectedClient.id"  
                                            :selectedClient="selectedClient" 
                    ></ClientSettingsPoidsScoresComponent>
                </div>
                <div>
                    <!-- Indicateurs financiers -->
                    <ClientSettingsIF1Component 
                                            v-if="selectedClient.id > 0" 
                                            ref="ClientSettingsIF1Component" 
                                            :key="selectedClient.id" 
                                            :clientId="selectedClient.id"
                                            :selectedClient="selectedClient" 
                    ></ClientSettingsIF1Component>
                </div>
                <div>
                    <!-- Indicateurs qualitatifs -->
                    <ClientSettingsIQ1Component 
                                            v-if="selectedClient.id > 0" 
                                            ref="ClientSettingsIQ1Component" 
                                            :key="selectedClient.id" 
                                            :clientId="selectedClient.id" 
                                            :selectedClient="selectedClient" 
                    ></ClientSettingsIQ1Component>
                </div>
                <div>
                    <!-- Recommandations -->
                    <ClientSettingsRec1Component 
                                            v-if="selectedClient.id > 0" 
                                            ref="ClientSettingsRec1Component" 
                                            :key="selectedClient.id" 
                                            :clientId="selectedClient.id" 
                    ></ClientSettingsRec1Component>
                </div>
                <div>
                    <!-- Recommandations -->
                    <ClientSettingsLimiteCredit1Component 
                                            v-if="selectedClient.id > 0" 
                                            ref="ClientSettingsLimiteCredit1Component" 
                                            :key="selectedClient.id" 
                                            :clientId="selectedClient.id"  
                                            :selectedClient="selectedClient" 
                    ></ClientSettingsLimiteCredit1Component>
                </div>
            </kendo-tabstrip>
        

            <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
        </div>



    </div>
</template>
<!-- ########################## <Script> ########################## -->
<script>
import kendo from '@progress/kendo-ui'
import Header from "./Header.vue";
import ClientSettingsPoidsScoresComponent from "./ClientSettings/ClientSettingsPoidsScoresComponent.vue";
import ClientSettingsIF1Component from "./ClientSettings/ClientSettingsIF1Component.vue";
import ClientSettingsIQ1Component from "./ClientSettings/ClientSettingsIQ1Component.vue";
import ClientSettingsRec1Component from "./ClientSettings/ClientSettingsRec1Component.vue";
import ClientSettingsLimiteCredit1Component from "./ClientSettings/LimiteCredit/ClientSettingsLimiteCredit1Component.vue";
import CRUDService from "../1-common-components/crud.service";
import {_} from 'vue-underscore';
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper';
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import { TabStrip } from '@progress/kendo-layout-vue-wrapper';
import Dropdown from 'primevue/dropdown';


export default {
    name: "AdminClientSettings",
    //--------------------------- Components --------------------------
    components: {
        Header, 
        ClientSettingsPoidsScoresComponent,
        ClientSettingsIF1Component,
        ClientSettingsIQ1Component,
        ClientSettingsRec1Component,
        ClientSettingsLimiteCredit1Component,
        'kendo-dropdownlist': DropDownList,
        'kendo-button': Button,
        'kendo-tabstrip': TabStrip,
        Dropdown,
        
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            listeDesClients :  null,
            selectedClient : {id:0, raisonSociale:"NA"},
            selectedDdlId : null,

            errorMessage : "",
           
        }
    }, //end data()
    computed: {    

    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("================================================================= mounted() => AdminClientSettings");
        
        CRUDService.list('client').then(
            (response) => {
                this.log(response.data);
                this.listeDesClients = response.data;
               // this.selectedClient = this.listeDesClients.length >0 ? this.listeDesClients[0] : {id:0};
                // var ddl = this.$refs.clientsDll.kendoWidget();   
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
        );
    }, //end mounted
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created()   => ClientSettings");
    },
    //-------------------------------- Methods --------------------------
    methods: {
        //----------------------------------Client changed --------------------------
        handleClientDdlValueChange(selectedDdlValue)
        {
            this.log("handleClientDdlValueChange");
            
            var client = this.listeDesClients.filter(d => d.id == selectedDdlValue.value);
            this.selectedClient = client[0];
            this.log(this.selectedClient);
            this.$store.commit('clientSettingsStoreModule/reset');
            this.toastInfo("Chargement du client '"+this.selectedClient.raisonSociale + "' est efféctué");
            
        },
    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
.echelleInfo
 {
    border-color: #4ba7f0;
    padding: 5px 10px 0px 10px;
    color: #2196f3;
    background-color: #2196f300;
    border-style: double;
    border-width: thin;
    width: 130px;
    height: 25px;
    border-left-style: unset;
    border-top-style: unset;
    border-radius: 0 0 4px 0;
 }

.p-dropdown {
    width: 100%;
}
</style>