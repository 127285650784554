<!-- ########################## <Template> ########################## -->
<template>
    <div  style="position: relative; min-height:30px">
         <!--  -->
         <div style="font-size:1.1rem;font-weight:bold">Paliers recommandations</div>
            <div :class="'topRightCorner'">
             
                <kendo-button icon="add" @kendoclick="fnAddRecommandation">ajouter recommandation</kendo-button>
            </div>
    </div>
            <div v-for="recommandation in listeChoix" :key="recommandation.id">
                <ClientSettingsRec2Choix        :recommandationId="recommandation.id"
                                                :clientId="recommandation.clientId"
                                                :scoreMin="recommandation.scoreMin"
                                                :scoreMax="recommandation.scoreMax"
                                                :natureRisque="recommandation.natureRisque"
                                                :couleur="recommandation.couleur"
                                                :recommandationAnalyse="recommandation.recommandationAnalyse"
                                                :lastOne = "recommandation.lastOne"
                                                @recommandationDeleted="onRecommandationDeleted"
                                                @recommandationChanged="onRecommandationChanged"
                 >
                 </ClientSettingsRec2Choix>        
            </div>
        <!--  -->
    
    
    <div  class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>

<!-- ########################## <Script> ########################## -->
<script>
import kendo from '@progress/kendo-ui'
import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import ClientSettingsRec2Choix from "./ClientSettingsRec2Choix.vue";

import {_} from 'vue-underscore';
import formuleTestData from './FormuleTestData.json';

export default {
    name: "ClientSettingsRec1Component",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button,
       ClientSettingsRec2Choix
    },
    //---------------------------- props ------------------------------
    props: {
        clientId: Number
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            listeChoix: [],
            errorMessage : "",
            colors: ['#37b400','#359df3','#f9f800','#ffa501','#fb0505']
        }

    }, //end data()
    computed: {
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("======================================================= mounted() => ClientSettingsRec1Component");
        this.fnLoadRecommandations();
    }, //end mounted

    //-------------------------------- Methods --------------------------
    methods: {
        
        fnLoadRecommandations()
        {
            var endpoint = this.isAdminApplication?'recommandationadmincustom':'recommandationclientcustom';
            
            CRUDService.get(this.clientId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadRecommandations reponse");
                this.log(response.data);
                this.listeChoix = response.data;
                if(this.listeChoix[0])
                {
                    this.listeChoix[0]['lastOne'] = true
                }
               
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        },
        
        fnAddRecommandation()
        {
            this.log("fnAddRecommandation");
            var len = this.listeChoix.length + 1;
            var couleur = (this.listeChoix.length < this.colors.length)?this.colors[this.listeChoix.length]:this.colors[this.colors.length-1];
           
            var nouveauRecommandation = { clientId: this.clientId, scoreMin:0, scoreMax:10, natureRisque: "Nature risque "+len, recommandationAnalyse: "Recommandation "+len, couleur:couleur};
            CRUDService.create(nouveauRecommandation,'recommandation').then(
                (response) => {
                    this.log(response.data);
                    this.listeChoix.push(response.data);

                     setTimeout(() => {
                        window.scrollTo(0, document.body.scrollHeight? document.body.scrollHeight : document.documentElement.scrollHeight);
                    }, 200); 
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },

        onRecommandationDeleted(deletedId)
        {
            
            this.log("onRecommandationDeleted: "+deletedId);
            for( var i = 0; i < this.listeChoix.length; i++){ 
                if ( this.listeChoix[i].id === deletedId) { 
                    this.listeChoix.splice(i, 1); 
                    //the if is a must because it is used to only change the lastOne flag if the deleted one is the first
                    if(i==0)
                    {
                        this.listeChoix[0]['lastOne'] = true
                    }
                }
            }
        },

        onRecommandationChanged(changedId,minValue)
        {
            debugger;
            this.log("onChoixChanged: "+changedId);
            for( var i = 0; i < this.listeChoix.length; i++){ 
                if ( this.listeChoix[i].id === changedId) { 
                    this.listeChoix[i].scoreMin = minValue
                    

                    var maxIndex = 0
                    this.listeChoix[maxIndex]['lastOne'] = false
                    for(var j=1;j<this.listeChoix.length;j++)
                    {
                        this.listeChoix[j]['lastOne'] = false
                        if(parseInt(this.listeChoix[j].scoreMin) > parseInt(this.listeChoix[maxIndex].scoreMin))
                        {
                            debugger;
                            maxIndex = j
                        }
                    }

                    this.listeChoix[maxIndex]['lastOne'] = true
                }
                
            }
        }

        

        


    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  

    
</style>