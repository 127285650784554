<!-- ########################## <Template> ########################## -->
<template>
    <div class="panel panel-default">
        <div style="font-size:1.1rem; font-weight: bold;">Poids & échelle score financier vs. qualitatif</div>
        <div class="divPanel" >
            <!--  -->
            <div style="display: inline">Poids score financier </div>
                <div style="display: inline;">
                    <InputText ref="inputPoidsScoreFinancier" 
                            v-model.number="selectedClient.poidsScoreFinancier"
                            type='number'
                            :min='0' :max='100' 
                            @input="onTextChange"
                    />
                </div>
            &nbsp;&nbsp;

            <kendo-slider @change="onSliderChange" v-model="selectedClient.poidsScoreFinancier"
                    :min="0"
                    :max="100"
                    :small-step="1" :large-step="10" :class="'slider'">
            </kendo-slider>

            &nbsp;
            <div style="display: inline; padding-left:20px">Poids score qualitatif</div>
            <div style="display: inline; padding-left:10px">
                    <InputText class="inputNote" v-model="poidsScoreQualitatif" :disabled="true" type='number' :min='0' :max='100'/>
            </div> 
            
            <!--  -->
            <div style = "padding-top:25px">
                
	            
            </div>
            <!--  -->
            

        </div>
        <div style="display:block; padding-top: 10px">
            <div style="font-size:1.1rem; font-weight: bold;">
                Workflow (soumission et validation score)
            </div>

            <div style="display:flex; align-items: center; padding-top:10px">
                <div flow="right"
                 tooltip="Permet d'activer la soumission et la validation des scores financiers et qualitatifs"
                 style="display:flex; align-items: center;">
                 &nbsp;&nbsp;Activation &nbsp;&nbsp;<InputSwitch  v-model="workflowActivationBoolean" @change="fnUpdateClientWorkflowValidation"/>
                </div>
            </div>
            
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        
    </div>
    <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../1-common-components/crud.service";
import { Slider } from '@progress/kendo-inputs-vue-wrapper';
import {_} from 'vue-underscore';
import { setTransitionHooks } from '@vue/runtime-core';

export default {
    name: "ClientSettingsPoidsScoresComponent",
    //--------------------------- Components --------------------------
    components: {
        'kendo-slider': Slider
    },
    //---------------------------- props ------------------------------
    props:   ['selectedClient'],
    //------------------------------ Data -----------------------------
    data() {
        return {
            listeIndicateurs: [],
            errorMessage : "",
            workflowActivationBoolean:false
        }

    }, //end data()
    computed: {
        poidsScoreFinancier: function()
        {
            return this.selectedClient.poidsScoreFinancier;
        },
        poidsScoreQualitatif: function () {       
            return (100  - this.selectedClient.poidsScoreFinancier);
        },
        noSelection: function () {
            return (this.selectedClient.id == 0);
        }
    },
    watch:
    {
        // workflowActivationBoolean: function(newFlag,oldFlag)
        // {
        //     debugger;
        //     if(oldFlag!=newFlag)
        //     {
        //         this.selectedClient.activerWorkflowValidation = newFlag?1:0;
        //     }
            
        // },
    },  
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientSettingsPoidsScoresComponent:");
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("======================================================= mounted() => ClientSettingsPoidsScoresComponent");
        this.workflowActivationBoolean = this.selectedClient.activerWorkflowValidation?true:false
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientSettingsPoidsScoresComponent: ");
    },

    //-------------------------------- Methods --------------------------
    methods: {
        
        fnUpdateClientWorkflowValidation(event)
        {

            this.log("fnUpdateClientWorkflowValidation");

            this.selectedClient.activerWorkflowValidation = this.workflowActivationBoolean?1:0;
    
            var endpoint = 'client/updateactiverworkflow';
            CRUDService.update(this.selectedClient,endpoint).then(
                (response) => {
                    this.log("--------------- returned fnSavePoidsScores response.data");
                    this.log(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
           // console.log(this.selectedClient.)
        },
        onSliderChange(ev)
        {
            debugger;
            this.log("fnSavePoidsScores");
           
            //this.$refs.inputPoidsScoreFinancier.value = ev.value;
            this.selectedClient.poidsScoreFinancier = ev.value;
            this.fnSavePoidsScores();
            
        },
        onTextChange()
        {
            if(!this.selectedClient.poidsScoreFinancier)
            {
                this.selectedClient.poidsScoreFinancier = 0
            }
            this.log("fnSavePoidsScores");
            this.fnSavePoidsScores();
            
        },
        fnSavePoidsScores()
        {
            this.log("fnSavePoidsScores");
           this.selectedClient.poidsScoreQualitatif = 100  - this.poidsScoreFinancier;
    
            var endpoint = this.isAdminApplication?'client/updatepoidsscores':'clientclient/updatepoidsscores';
            CRUDService.update(this.selectedClient,endpoint).then(
                (response) => {
                    this.log("--------------- returned fnSavePoidsScores response.data");
                    this.log(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
.slider
{
    width:400px;
}

.k-slider-horizontal .k-slider-buttons .k-slider-track {
    left: 34px;
    margin-top: 6px;
}

.k-slider-buttons .k-slider-items {
        margin-left: 60px !important;
    }

.k-label {
    margin-bottom: 15px;
}
</style>