<!-- ########################## <Template> ########################## -->
<template>
    <div class="divPanel" style="position: relative;">
         <!--  -->
            <br/>

            <!-- topLeftCorner -->
            <div :class="'topLeftCorner'">
                <div class="echelleInfo">
                    {{echelleIndicateur}} / {{totalEchellesIQs}} = {{formatDecimal2(echelleIndicateur/totalEchellesIQs *100)}}%
                </div>
            </div>

            <!-- topRightCorner -->
            <div :class="'topRightCorner'">
                <kendo-button icon="delete" @kendoclick="fnDeleteIndicateur">supprimer indicateur</kendo-button>
                &nbsp;
                <kendo-button icon="add" @kendoclick="fnAddIndicateurChoix">ajouter choix</kendo-button>
                &nbsp;
                <a class="k-button" href="#"  v-handle>
                    <span class="k-icon k-i-list-ordered" ></span> ordre
                </a>
            </div>           

            <!-- Indicateur info -->
            <div class="row">
                <div class="col-1">Libelle</div>
                <div class="col-10"><InputText  style="width:100%"  type="text" v-model="libelleIndicateurLocal" @change="fnSaveIndicateur" /> </div>
            </div>

            
            <div class="row">
                <div class="col-1">Echelle</div>
                <div class="col-8">
                    <InputText ref="echelleInput" 
                            v-model="echelleIndicateurValue"
                            :min="0"  :max="100"
                            :class="'inputNote'"
                            type="number"
                            @change="fnSaveIndicateur"
                    />
                </div>
            </div> 
            
            <!-- Indicateur choix -->
            <hr class="my-12" style="color:rgb(61, 87, 216)"/>
            <div v-for="choix in listeChoix" :key="choix.id" style="padding-top:4px">
                <ClientSettingsIQ3Choix    :choixId="choix.id"
                                                :libelleChoix="choix.libelleChoix"
                                                :note="choix.note"
                                                :indicateurId="choix.indicateurId"
                                                :ordre="choix.ordre"
                                                @choixDeleted="onChoixDeleted"
                 >
                 </ClientSettingsIQ3Choix>         
            </div>
        <!--  -->
    </div>
    <div  class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>
<!-- ########################## <Script> ########################## -->
<script>
import kendo from '@progress/kendo-ui'
import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import ClientSettingsIQ3Choix from "./ClientSettingsIQ3Choix.vue";
import {_} from 'vue-underscore';
import { HandleDirective } from 'vue-slicksort';
import { mapGetters } from 'vuex'

export default {
    name: "ClientSettingsIQ",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button,
       ClientSettingsIQ3Choix
    },
    //---------------------------- directives ------------------------------
    directives: { handle: HandleDirective },
    //---------------------------- props ------------------------------
    props: {
        indicateurId: Number,
        libelleIndicateur: String,
        clientId: Number,
        ordre: Number
    },
    //---------------------------- emits ------------------------------
    emits: ["indicateurDeleted"],
    //------------------------------ Data -----------------------------
    data() {
        return {
            listeChoix: [],
            errorMessage : "",
            echelleIndicateurValue : 0,
            libelleIndicateurLocal:""
        }

    }, //end data()
    computed: {
        ...mapGetters("clientSettingsStoreModule",[
            'listeIndicateursIQ',
            'totalEchellesIQs',
            'getEchelleIQ',
        ]),
        echelleIndicateur()
        {
            return this.getEchelleIQ(this.indicateurId);
        }
    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientSettingsIQ: clientId:" + this.clientId);
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("============================================== mounted() => ClientSettingsIQ2Element");
        this.fnLoadIndicateursChoix();
        this.libelleIndicateurLocal = this.libelleIndicateur
        this.echelleIndicateurValue = this.echelleIndicateur;
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientSettingsIQ: clientId:" + this.clientId);
    },

    //-------------------------------- Methods --------------------------
    methods: {
        
        fnLoadIndicateursChoix()
        {
            var endpoint = this.isAdminApplication?'indicateursqualitatifschoixadmincustom':'indicateursqualitatifschoixclientcustom';
            
            CRUDService.get(this.indicateurId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadIndicateursChoix reponse");
                this.log(response.data);
                this.listeChoix = response.data;
                
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        },
        
        
        fnDeleteIndicateur()
        {
            let confirmAction = confirm("êtes-vous sûr de vouloir continuer la suppression?");
            if (confirmAction) {
                this.log("fnDeleteIndicateur");
                var endpoint = this.isAdminApplication?'indicateurqualitatif':'indicateurqualitatifclient';

                CRUDService.delete(this.indicateurId,endpoint).then(
                    (response) => {
                        this.log(response.data);
                        this.$emit("indicateurDeleted",this.indicateurId);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 
            } else {
                this.log("Suppression annulée");
            }
        },

        fnSaveIndicateur()
        {
            this.log("fnSaveIndicateur");
 
            var indicateurToUpdate = { id: this.indicateurId, clientId: this.clientId, libelleIndicateur: this.libelleIndicateurLocal, echelleIndicateur: this.echelleIndicateurValue };
           
            this.log(indicateurToUpdate);
            var endpoint = this.isAdminApplication?'indicateurqualitatif':'indicateurqualitatifclient';
            CRUDService.update(indicateurToUpdate,endpoint).then(
                (response) => {
                    this.log(response.data);
                    this.$store.commit('clientSettingsStoreModule/updateIQsList', response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            ); 
        },

        fnAddIndicateurChoix()
        {
            this.log("fnAddIndicateurChoix");
            var nouveauIndicateurChoix = { indicateurId: this.indicateurId, libelleChoix:"Libelle Choix "+(this.listeChoix.length+1), note: 0, ordre: this.listeChoix.length};
            var endpoint = this.isAdminApplication?'indicateurqualitatiflistechoix':'indicateurqualitatiflistechoixclient';
            
            CRUDService.create(nouveauIndicateurChoix,endpoint).then(
                (response) => {
                    this.log(response.data);
                    this.listeChoix.push(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },

        onChoixDeleted(deletedId)
        {
            this.log("onChoixDeleted: "+deletedId);
            for( var i = 0; i < this.listeChoix.length; i++){ 
                if ( this.listeChoix[i].id === deletedId) { 
                    this.listeChoix.splice(i, 1); 
                }
            }
        },


    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style scoped>
  
.row 
{
    padding-top:4px;
}
    
</style>