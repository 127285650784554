<!-- ########################## <Template> ########################## -->
<template lang="">
    <div class="divPanel recommandationHeader " style="position: relative;">
                <div :class="'topRightCorner'">
                    <kendo-button icon="delete" @kendoclick="fnDeleteRecommandation"></kendo-button>
                </div>
        <!--  -->
                
                <table style="width:95%">
                    <tr>
                        <td style="width:150px">Nature risque &nbsp;</td>
                        <td><Textarea @change="fnSaveRecommandation" type="text"  style="width:100%" v-model="natureRisqueLocal" /> </td>
                    </tr>
                    <tr>
                        <td>Recommandation &nbsp;</td>
                        <td><Textarea @change="fnSaveRecommandation" type="text" style="width:100%" v-model="recommandationAnalyseLocal" /> </td>
                    </tr>
                    <tr>
                        <td>Score min &nbsp;<span>>=</span>&nbsp;</td>
                        <td>
                            <InputText type="number" @change="fnSaveRecommandation" v-model="scoreMinLocal"	 :min="-10" :max="scoreMax" :class="'inputNote'" />/10
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Score max <span v-if="lastOne">&lt;=</span><span v-else>&lt;</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <InputText type="number" @change="fnSaveRecommandation" v-model="scoreMaxLocal"	 :min="-10" :max="10" :class="'inputNote'" />/10
                        </td>
                    </tr>
                    <tr>
                        <td>Couleur KPI &nbsp;</td>
                        <td>                           
                            <Dropdown v-model="couleurLocal" :options="colors"  @change="fnSaveRecommandation">
                                <template #value="slotProps">
                                    <div  v-if="slotProps.value">
                                        <div :style="{backgroundColor: slotProps.value, padding: '10px'}">{{slotProps.value}}</div>
                                    </div>
                                    <span v-else>
                                        {{slotProps.placeholder}}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <div  :style="{backgroundColor: slotProps.option, padding: '2px'}"  >
                                        <div >{{slotProps.option}}</div>
                                    </div>
                                </template>
                            </Dropdown>
                        </td>
                    </tr>
                   
                </table>
                  
        
        <!--  -->
    </div>
    <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import {_} from 'vue-underscore';

export default {
    name: "ClientSettingsRec2Choix",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button
    },
    //---------------------------- emits ------------------------------
    emits: ["recommandationDeleted","recommandationChanged"],
    //---------------------------- props ------------------------------
    props: {
        recommandationId: Number,
        clientId: Number,
        scoreMin: Number,
        scoreMax: Number,
        natureRisque: String,
        recommandationAnalyse: String,
        couleur: String,
        lastOne:Boolean
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            scoreMinLocal: 0,
            scoreMaxLocal: 0,
            natureRisqueLocal: "",
            recommandationAnalyseLocal: "",
            couleurLocal: "",
            errorMessage : "",
            colors: ['#37b400','#359df3','#f9f800','#ffa501','#fb0505']
        }

    }, //end data()
    mounted()
    {
        this.scoreMinLocal= this.scoreMin;
        this.scoreMaxLocal= this.scoreMax;
        this.natureRisqueLocal= this.natureRisque;
        this.recommandationAnalyseLocal= this.recommandationAnalyse;
        this.couleurLocal= this.couleur;
    },
     //-------------------------------- computed --------------------------
    computed: {
    
    },
    //-------------------------------- Methods --------------------------
    methods: {
        
        fnDeleteRecommandation()
        {
            this.log("----------------- fnDeleteRecommandation");
            let confirmAction = confirm("êtes-vous sûr de vouloir continuer la suppression?");
            if (confirmAction) {
                CRUDService.delete(this.recommandationId,'recommandation').then(
                    (response) => {
                        this.log(response.data);
                        this.$emit("recommandationDeleted",this.recommandationId);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 
            } else {
                this.log("Suppression annulée");
            }
            
        },
        fnSaveRecommandation()
        {
            this.log("----------------- fnSaveRecommandation");
            var recommandationToUpdate = {  id: this.recommandationId, 
                                            clientId: this.clientId, 
                                            scoreMin: this.isNumeric(this.scoreMinLocal) ?this.scoreMinLocal:-1, 
                                            scoreMax: this.isNumeric(this.scoreMaxLocal) ?this.scoreMaxLocal:101, 
                                            natureRisque: this.natureRisqueLocal?this.natureRisqueLocal:0,
                                            recommandationAnalyse: this.recommandationAnalyseLocal?this.recommandationAnalyseLocal:0,
                                            couleur: this.couleurLocal?this.couleurLocal:"",
                                        };

            this.log(recommandationToUpdate);
            CRUDService.update(recommandationToUpdate,'recommandation').then(
                (response) => {
                    this.log(response.data);
                    this.$emit("recommandationChanged",this.recommandationId,this.scoreMinLocal);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            ); 
        }

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
  .recommandationHeader
  {
      padding-left:30px;
  }

    
</style>