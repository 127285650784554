<!-- ########################## <Template> ########################## -->
<template lang="">
    <div class="choixHeader ">
         <!--  -->
                <span class="k-icon k-i-round-corners"></span>
                Choix: <InputText @change="fnSaveIndicateurChoix" type="text" v-model="libelleChoixLocal"  :modelValue="libelleChoixLocal" style="width:400px" /> 
                Note: <InputText  type="number" 
                                @change="fnSaveIndicateurChoix" 
                                v-model="notelocale"	 
                                :min="-99" 
                                :max="echelleIndicateur" 
                                class="inputNote"
                                :class="validNoteClass"
                                
                        />
                 / {{echelleIndicateur}}
                &nbsp;

                <kendo-button icon="delete" @kendoclick="fnDeleteIndicateurChoix"></kendo-button>
        <!--  -->
    </div>
    <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import {_} from 'vue-underscore';
import { mapGetters } from 'vuex'

export default {
    name: "ClientSettingsIQChoix",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button
    },
    //---------------------------- emits ------------------------------
    emits: ["choixDeleted"],
    //---------------------------- props ------------------------------
    props: {
        choixId: Number,
        libelleChoix: String,
        note: Number,
        indicateurId: Number,
        ordre: Number
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            errorMessage : "",
            notelocale:0,
            libelleChoixLocal:0
        }

    }, //end data()
    //------------------------------ computed -----------------------------
    computed: {
        
        validNoteClass()
        {
            var isValid = this.notelocale <= this.echelleIndicateur;
            return isValid?"formuleValide" :"formuleNonValide";
        },
        ...mapGetters("clientSettingsStoreModule",[
            'listeIndicateursIQ',
            'totalEchellesIQs',
            'getEchelleIQ',
        ]),
        echelleIndicateur()
        {
            return this.getEchelleIQ(this.indicateurId);
        }
    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientSettingsIQChoix: " + this.indicateurId);
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("==================================== mounted() => ClientSettingsIQ3Choix");
        this.notelocale = this.note
        this.libelleChoixLocal = this.libelleChoix
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientSettingsIQChoix: " + this.indicateurId);
    },

    //-------------------------------- Methods --------------------------
    methods: {

        fnDeleteIndicateurChoix()
        {
            this.log("----------------- fnDeleteIndicateurChoix");
            let confirmAction = confirm("êtes-vous sûr de vouloir continuer la suppression?");
            if (confirmAction) {
                var endpoint = this.isAdminApplication?'indicateurqualitatiflistechoix':'indicateurqualitatiflistechoixclient';
            
                CRUDService.delete(this.choixId,endpoint).then(
                    (response) => {
                        this.log(response.data);
                        this.$emit("choixDeleted",this.choixId);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 
            } else {
                this.log("Suppression annulée");
            }
            

        },
        fnSaveIndicateurChoix()
        {
            this.log("----------------- fnSaveIndicateurChoix");

            debugger;
            var choixToUpdate = { id: this.choixId,
                                 indicateurId: this.indicateurId, 
                                 libelleChoix: this.libelleChoixLocal,
                                 note: this.notelocale?this.notelocale:0};
            
            
            this.log(choixToUpdate);
            var endpoint = this.isAdminApplication?'indicateurqualitatiflistechoix':'indicateurqualitatiflistechoixclient';
            
            CRUDService.update(choixToUpdate,endpoint).then(
                (response) => {
                    this.log(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            ); 
        }

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
  .choixHeader
  {
      padding-left:30px;
  }

    
</style>