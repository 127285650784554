<!-- ########################## <Template> ########################## -->
<template>
    <div class="divPanel" style="position: relative;">
        <!--  -->
            <br/>
            <div :class="'topLeftCorner'">
                <div class="echelleInfo">
                    {{echelleIndicateur}} / {{totalEchellesIFs}} = {{formatDecimal2(echelleIndicateur/totalEchellesIFs *100)}}%
                </div>
            </div>
            <div class="row">
                <div class="col-1">Libelle</div>
                <div class="col-10"><InputText  style="width:100%"  type="text" v-model="libelleIndicateurLocal" @change="fnSaveIndicateur" /> </div>
            </div>
            <div class="row">
                <div class="col-1">Echelle</div>
                <div class="col-8">
                    <InputText ref="echelleInput" 
                            v-model.number="echelleIndicateurValue"
                            :min="0"  :max="100"
                            :class="'inputNote'"
                            type="number"
                            @change="fnSaveIndicateur"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-1">Formule</div>
                <!-- {{formule}} -->
                <div class="col-10"><InputText  style="width:100%" :class="formuleStatus" type="text" v-model="formuleLocal" @change="fnSaveIndicateur" /></div>
                <P-Button icon="k-icon k-i-formula-fx" class="p-button-rounded p-button-secondary p-button-outlined" @click="fnFormuleInfoOpenEmit" v-tooltip.top="'Aide formule'" style="margin-top: 2px;"/>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-10"><span style="font-size:12px">{{resultatFormule}}</span></div>
            </div>

            <div :class="'topRightCorner'">
                <!-- <kendo-button icon="formula-fx" @kendoclick="fnTestFormule">tester formule</kendo-button> -->
                &nbsp;
                <kendo-button icon="delete" @kendoclick="fnDeleteIndicateur">supprimer indicateur</kendo-button>
                &nbsp;
                <kendo-button icon="add" @kendoclick="fnAddIndicateurChoix">ajouter choix</kendo-button>
                &nbsp;
                <a class="k-button" href="#"  v-handle>
                    <span class="k-icon k-i-list-ordered" ></span> ordre
                </a>
            </div>

            <hr class="my-12" style="color:rgb(61, 87, 216)"/>

            <div v-for="choix in listeChoix" :key="choix.id" style="padding-top:4px">
                <ClientSettingsIF3Choix         :key="choix.id"
                                                :choixId="choix.id"
                                                :min="choix.min"
                                                :max="choix.max"
                                                :note="choix.note"
                                                :indicateurId="choix.indicateurId"
                                                :ordre="choix.ordre"
                                                :lastOne = "choix.lastOne"
                                                @choixDeleted="onChoixDeleted"
                                                @choixChanged="onChoixChanged"
                 >
                 </ClientSettingsIF3Choix>         
            </div>
        <!--  -->
    </div>
    
    <div  class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>
<!-- ########################## <Script> ########################## -->
<script>
import kendo from '@progress/kendo-ui'
import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import ClientSettingsIF3Choix from "./ClientSettingsIF3Choix.vue";

import {_} from 'vue-underscore';
import formuleTestData from './FormuleTestData.json';
import { HandleDirective } from 'vue-slicksort';
import CommonService from '../../1-common-components/common.service';
import Badge from 'primevue/badge';
import { mapGetters } from 'vuex'

export default {
    name: "ClientSettingsIF",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button,
       ClientSettingsIF3Choix,
       Badge
    },
    //---------------------------- directives ------------------------------
    directives: { handle: HandleDirective },
    //---------------------------- props ------------------------------
    props: {
        indicateurId: Number,
        libelleIndicateur: String,
        formule: String,
        clientId: Number,
        ordre: Number
    },
    //---------------------------- emits ------------------------------
    emits: ["indicateurDeleted","OpenIF2"],
    //------------------------------ Data -----------------------------
    data() {
        return {
            listeChoix: [],
            errorMessage : "",
            formuleStatus: "formuleValide",
            echelleIndicateurValue : 0,
            libelleIndicateurLocal: "",
            formuleLocal:"",
        }

    }, //end data()
    computed: {
        resultatFormule()
        {
            return CommonService.getResultatFormule(this.formule, formuleTestData,this);
        },
        ...mapGetters("clientSettingsStoreModule",[
            'listeIndicateursIF',
            'totalEchellesIFs',
            'getEchelleIF',
        ]),
        echelleIndicateur()
        {
            return this.getEchelleIF(this.indicateurId);
        }
    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientSettingsIF: clientId:" + this.clientId);
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("============================================== mounted() => ClientSettingsIF2Element");
        this.fnLoadIndicateursChoix();
        this.libelleIndicateurLocal = this.libelleIndicateur
        this.echelleIndicateurValue = this.echelleIndicateur;
        this.formuleLocal = this.formule
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientSettingsIF: clientId:" + this.clientId);
    },

    //-------------------------------- Methods --------------------------
    methods: {
        
        fnLoadIndicateursChoix()
        {
            var endpoint = this.isAdminApplication?'indicateursfinancierschoixadmincustom':'indicateursfinancierschoixclientcustom';
            
            CRUDService.get(this.indicateurId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadIndicateursChoix reponse: "+ endpoint);
                this.log(response.data);
                this.listeChoix = response.data;
                this.listeChoix[this.listeChoix.length-1]['lastOne'] = true
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        },
        
        
        fnDeleteIndicateur()
        {
            let confirmAction = confirm("êtes-vous sûr de vouloir continuer la suppression?");
            if (confirmAction) {
                this.log("fnDeleteIndicateur");
                CRUDService.delete(this.indicateurId,'indicateurfinancier').then(
                    (response) => {
                        this.log(response.data);
                        this.$emit("indicateurDeleted",this.indicateurId);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 
            } else {
                this.log("Suppression annulée");
            }
        },

        fnSaveIndicateur()
        {
            this.log("fnSaveIndicateur");

            
            var indicateurToUpdate = { id: this.indicateurId, clientId: this.clientId, libelleIndicateur: this.libelleIndicateurLocal, formule: this.formuleLocal , echelleIndicateur: this.echelleIndicateurValue };
            
            this.log(indicateurToUpdate);
            var endpoint = this.isAdminApplication?'indicateurfinancier':'indicateurfinancierclient';
            CRUDService.update(indicateurToUpdate,endpoint).then(
                (response) => {
                    this.log(response.data);
                    this.$store.commit('clientSettingsStoreModule/updateIFsList', response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            ); 
        },

        fnAddIndicateurChoix()
        {
            this.log("fnAddIndicateurChoix");
            var nouveauIndicateurChoix = { indicateurId: this.indicateurId, min:0, max:0, note: 0, ordre: this.listeChoix.length};
            CRUDService.create(nouveauIndicateurChoix,'indicateurfinancierlistechoix').then(
                (response) => {
                    this.log(response.data);
                    this.listeChoix.push(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },

        onChoixDeleted(deletedId)
        {
            this.log("onChoixDeleted: "+deletedId);
            for( var i = 0; i < this.listeChoix.length; i++){ 
                if ( this.listeChoix[i].id === deletedId) { 
                    this.listeChoix.splice(i, 1); 
                    if(i==this.listeChoix.length)
                    {
                        this.listeChoix[this.listeChoix.length-1]['lastOne'] = true
                    }
                }
            }
        },

        onChoixChanged(changedId,minValue)
        {
            this.log("onChoixChanged: "+changedId);
            for( var i = 0; i < this.listeChoix.length; i++){ 
                if ( this.listeChoix[i].id === changedId) { 
                    this.listeChoix[i].min = minValue
                    

                    var maxIndex = this.listeChoix.length-1
                    this.listeChoix[maxIndex]['lastOne'] = false
                    for(var j=0;j<this.listeChoix.length-1;j++)
                    {
                        this.listeChoix[j]['lastOne'] = false
                        if(parseInt(this.listeChoix[j].min) > parseInt(this.listeChoix[maxIndex].min))
                        {
                            debugger;
                            maxIndex = j
                        }
                    }

                    this.listeChoix[maxIndex]['lastOne'] = true
                }
                
            }
        },  

        fnTestFormule()
        {
            var resultString = "";
            try{
            var template = kendo.template("#= "+this.formule+" #");
            var data = formuleTestData; //A value in JavaScript/JSON
            this.log("fnTestFormule");
            
                var resultString = template(data);
                var resultFloat = parseFloat(resultString);
                alert(resultFloat);
            }
            catch(error) {
                alert("Formule invalide !");
                this.log("----------- error:"+ error);
            } 
        },
        fnFormuleInfoOpenEmit()
        {
            this.$emit('OpenIF2');
        },

 
    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style scoped>
  
.row 
{
    padding-top:4px;
}


</style>